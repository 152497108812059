<template>
  <div class="content">
    <loading :empty="empty" :loading="loading"></loading>
    <div class="view">
      <div class="title">
        <p>{{ result.title }}
<!--          <span>{{ result.updatetime | time }}</span>-->
        </p>

      </div>

      <div class="tuwen">
        <p v-html="result.content"></p>
      </div>
      <div class="tuwen">
        <p v-html="result.summary_content"></p>
      </div>


<!--      <div class="demo-image" v-if="type == 2">-->
<!--        <div class="block" v-for="fit in showimg" :key="fit">-->
<!--          <el-image-->
<!--              style="width: 130px; height: 130px"-->
<!--              :src="$url+fit.image"-->
<!--              :fit="scale-down"></el-image>-->
<!--        </div>-->
<!--      </div>-->
    </div>

  </div>
</template>

<script>
import moment from "moment";
import loading from "../components/loading";

export default {
  name: "exchangeView",
  data() {
    return {
      result: {},
      loading: true,
      empty: false,
      type: this.$route.query.type,
      fits: ['fill', 'contain', 'cover', 'none', 'scale-down'],
      showimg: [],
    }
  },
  created() {
    this.fetchData()

    this.getshowimg()

  },
  components: {
    loading
  },
  methods: {
    fetchData() {
      this.empty = false
      this.loading = true
      this.$get('index/newInfo', {params: this.$route.query})
          .then(res => {
            this.loading = false
            this.result = res.data ? res.data : {}
            if (!this.result.title) {
              this.empty = true
            }
          })
    },
    getshowimg() {
      this.$get('index/showimg')
          .then(res => {
            this.showimg = res.data
          })
    },
  },
  filters: {
    time(dataStr) {
      if (!dataStr || dataStr == 0) {
        return '              -'
      } else {
        return moment(dataStr * 1000).format('YYYY-MM-DD HH:mm:ss')
      }
    }
  },
  watch: {
    $route() {
      this.fetchData()
    }
  }
}
</script>

<style scoped lang="less">
/deep/ storng{
  font-weight: bold;
}
/deep/  img {
  max-width: 100%; /* 图片最大宽度为100% */
  height: auto;
}
.demo-image {
  display: block;
}

.block {
  padding: 30px 0;
  text-align: center;
  //border-right: 1px solid #eff2f6;
  display: inline-block;
  width: 20%;
  box-sizing: border-box;
  vertical-align: top;

}

.el-image {
  position: relative;
  display: inline-block;
  overflow: hidden;
}

.demonstration {
  display: block;
  color: #8492a6;
  margin-top: 20px;
  font-size: 14px;
  margin-bottom: 20px;
}

.content {
  max-width: 70vw;
  min-height: 100vh;
  margin: 0 auto;
}

.adv {
  height: 7.8125vw;
  width: 100%;
  margin: auto;
}

.content {
  width: 100%;
  min-height: calc(100vh - 10.8333vw - 4.1667vw);

  .view {
    width: 52.0833vw;
    height: auto;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    padding-top: 4.1667vw;

    .title {
      p {
        width: 100%;
        font-size: 1.6vw;
        font-weight: bold;
        color: #333333;
        line-height: 2.1833vw;
      }

      span {
        vertical-align: bottom;
        font-size: 0.6250vw;
        font-weight: 550;
        float: right;
        color: #999999;
      }


    }

    .tuwen {
      width: 100%;
      display: flex;
      margin-top: 3.6458vw;
      p {
        flex: 1;
        font-size: 0.8333vw;
        font-weight: 550;
        overflow: hidden;
        color: #333333;
        line-height: 1.6667vw;
        margin-right: 3.6458vw;
        img {
          max-width: 100%; /* 图片最大宽度为100% */
          height: auto;
          //height: 10.4167vw;
        }
        p{
        img {
          max-width: 100%; /* 图片最大宽度为100% */
          height: auto;
          //height: 10.4167vw;
        }
        }

      }
    }
  }
}
</style>
